<!-- @format -->

<template>
  <v-app>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Editor Login</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  name="email"
                  label="E-Mail"
                  type="email"
                  :rules="emailRules"
                  data-cy="joinEmailField"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  name="password"
                  label="Password"
                  type="password"
                  required
                  :rules="passwordRules"
                  data-cy="joinPasswordField"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn vlass="vbtn" color="#f00">
                <router-link class="vbtn__back" to="/">Zurück</router-link>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="vbtn"
                color="#43A047"
                :disabled="!valid"
                data-cy="joinSubmitBtn"
                @click="submit"
                >Login</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  /** @format */

  export default {
    name: 'Login',
    data() {
      return {
        valid: false,
        email: '',
        password: '',
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
        passwordRules: [
          (v) => !!v || 'Password is required',
          (v) => v.length >= 6 || 'Password must be greater than 6 characters',
        ],
      }
    },
    methods: {
      submit() {
        if (this.$refs.form.validate()) {
          this.$store.dispatch('Auth/userLogin', {
            email: this.email,
            password: this.password,
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .vbtn {
    color: white;
    font-weight: 700;
    &__back {
      text-decoration: none;
      color: white;
      font-weight: 700;
    }
  }
</style>
