<!-- @format -->

<template>
  <div>
    <div class="index">
      <div class="index__praedikat" :class="$mq">
        <a
          href="https://www.fbw-filmbewertung.com/film/russland_von_oben"
          target="_blank"
          ><img
            src="@/assets/images/praedikat_wertvoll_russlandvonoben.png"
            alt=""
        /></a>
      </div>
      <div class="index__packshot" :class="$mq">
        <a
          href="https://www.amazon.de/Russland-Von-Oben-Blu-ray/dp/B08LNG4G7W?tag=filmweltverle-21"
          target="_blank"
          ><img src="@/assets/images/packshot.png" alt=""
        /></a>
      </div>
      <a
        v-if="$i18n.locale === 'de'"
        class="index__schulmaterial"
        :class="$mq"
        href="RusslandVonOben_Filmheft_final.pdf"
        target="_blank"
      >
        <img src="@/assets/images/Schulmaterial_Russlandvonoben.png" alt="" />
      </a>
      <div class="index__container">
        <div class="index__tt" :class="$mq">
          <img
            :src="
              require('@/assets/images/' +
                $config.tt +
                '_' +
                $i18n.locale +
                '.png')
            "
            alt=""
          />
        </div>
        <div class="index__kinostart">
          <BaseKinostart :kinostart="kinostart" />
        </div>
        <div class="index__subline" :class="$mq">
          <img
            :src="require(`@/assets/images/subline_${$i18n.locale}.png`)"
            alt=""
          />
        </div>
        <div class="index__trailer" @click="yt_video">
          <div class="index__trailer-btn">
            <youtube /> {{ $t('index.trailer') }}
          </div>
        </div>
      </div>
    </div>
    <div class="subline2" :class="$mq">
      <img
        :src="require(`@/assets/images/subline2_${$i18n.locale}.png`)"
        alt=""
      />
    </div>
    <BaseFooter :billing="true" :youtubenote="false" :kinostart="kinostart" />
  </div>
</template>

<script>
  import moment from 'moment'
  import { EventBus } from '@/plugins/event-bus.js'
  import Youtube from 'vue-material-design-icons/Youtube.vue'

  export default {
    components: {
      Youtube,
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
    },
    methods: {
      yt_video() {
        EventBus.$emit('open-yt')
      },
    },
  }
</script>

<style lang="scss">
  .subline2 {
    position: absolute;
    top: 70px;
    right: 20px;
    &.lg {
      text-align: right;
      img {
        width: 70%;
      }
    }
  }
  .index {
    $self: &;
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    &__praedikat {
      position: absolute;
      top: 70px;
      left: 10px;
      text-align: left;
      &.lg {
        img {
          width: 50%;
        }
      }
      &.xl,
      &.xxl {
        img {
          width: 70%;
        }
      }
    }
    &__packshot {
      position: absolute;
      bottom: 20px;
      left: 20px;
      text-align: left;
      &.lg {
        img {
          width: 70%;
        }
      }
      &.xl,
      &.xxl {
        img {
          width: 85%;
        }
      }
    }
    &__schulmaterial {
      position: absolute;
      bottom: 25px;
      right: 25px;
      text-align: right;
      &.lg {
        img {
          width: 70%;
        }
      }
    }
    &__container {
      height: 100%;
      width: 960px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      #{$self}__tt {
        margin-top: $header-height + 5;
        margin-bottom: 2%;
        &.lg {
          img {
            width: 50%;
          }
        }
        &.xl {
          img {
            width: 75%;
          }
        }
      }
      #{$self}__subline {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20%;
        &.lg {
          height: 14%;
          img {
            width: 40%;
          }
        }
        &.xl {
          img {
            width: 75%;
          }
        }
      }
      #{$self}__trailer {
        cursor: pointer;
        margin-bottom: 30px;
        #{$self}__trailer-btn {
          display: inline-block;
          text-transform: uppercase;
          font-weight: 700;
          color: #fff;
          font-size: 1.4rem;
          background-color: #000;
          padding: 9px 20px;
        }
      }
    }
  }
</style>
