<!-- @format -->

<template>
  <div>
    <div class="inhalt" :class="{ smaller: isSmaller }">
      <div ref="box" class="inhalt__container">
        <div class="inhalt__tt" :class="$mq">
          <img
            :src="
              require('@/assets/images/' +
                $config.tt +
                '_' +
                $i18n.locale +
                '.png')
            "
            alt=""
          />
        </div>
        <div class="inhalt__text" :class="$mq" v-html="$t('inhalt.text')"></div>
        <div class="inhalt__pictures">
          <img src="@/assets/images/inhalt_pic.png" alt="" />
        </div>
      </div>
    </div>
    <BaseFooter :billing="true" :youtubenote="false" :kinostart="kinostart" />
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    data() {
      return {
        containerHeight: 0,
        isSmaller: true,
      }
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
    },
    created() {
      window.addEventListener('load', () => {
        // window.dispatchEvent(new Event('resize'))
      })
    },
    mounted() {
      this.$nextTick(function () {
        /* window.addEventListener('resize', () => {
          this.containerHeight = this.$refs.box.clientHeight
          this.isSmaller =
            this.$store.getters['Window/isSize'].height <
            this.containerHeight + 153
        }) */
      })
    },
  }
</script>

<style lang="scss">
  .inhalt {
    $self: &;
    position: relative;
    height: 100vh;
    margin: 0 auto;
    &__container {
      margin-top: 6%;
      display: inline-block;
      width: 960px;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 25px 60px 40px 60px;
      #{$self}__tt {
        &.lg {
          img {
            width: 60%;
          }
        }
      }
      #{$self}__text {
        font-size: 20px;
        padding-top: 44px;
        padding-bottom: 32px;
        text-align: left;
        color: white;
        &.lg {
          font-size: 16px;
        }
      }
    }
  }

  .smaller {
    height: auto !important;
    padding-bottom: 50px;
  }
</style>
