<!-- @format -->

<template>
  <div>
    <div class="dreharbeiten" :class="{ smaller: isSmaller }">
      <div ref="box" class="dreharbeiten__container">
        <div class="dreharbeiten__pictures">
          <img src="@/assets/images/dreh_pic.png" alt="" />
        </div>
        <div
          class="dreharbeiten__text"
          :class="$mq"
          v-html="$t('dreharbeiten.text')"
        ></div>
      </div>
    </div>
    <BaseFooter :billing="true" :youtubenote="false" :kinostart="kinostart" />
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    data() {
      return {
        containerHeight: 0,
        isSmaller: true,
      }
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
    },
    created() {
      window.addEventListener('load', () => {
        // window.dispatchEvent(new Event('resize'))
      })
    },
    mounted() {
      this.$nextTick(function () {
        /* window.addEventListener('resize', () => {
          this.containerHeight = this.$refs.box.clientHeight
          this.isSmaller =
            this.$store.getters['Window/isSize'].height <
            this.containerHeight + this.$refs.box.getBoundingClientRect().top
        }) */
      })
    },
  }
</script>

<style lang="scss">
  .dreharbeiten {
    $self: &;
    position: relative;
    height: 100vh;
    margin: 0 auto;
    &__container {
      margin-top: 6%;
      display: inline-block;
      width: 960px;
      background-color: rgba(0, 0, 0, 0.6);
      padding: 53px 60px 40px 60px;
      #{$self}__text {
        font-size: 20px;
        padding-top: 44px;
        padding-bottom: 32px;
        text-align: left;
        color: white;
        h1 {
          font-size: 34px;
          margin-bottom: 2rem;
        }
        &.lg {
          font-size: 16px;
          h1 {
            font-size: 24px;
          }
        }
      }
    }
  }

  .smaller {
    height: auto !important;
    padding-bottom: 50px;
  }
</style>
